
import { Component, Vue } from 'vue-property-decorator';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';
import workflowModule from '../store/modules/workflowModule';
import CustomTextCell from '@/views/agGrid/CustomTextCell.vue';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
// import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    AgGridVue,
    FileUpload,
    CustomTextCell
  },
})
export default class TicketSupport extends Vue {
  context: any = null;

  columnDefsActiveStories: any = [];

  isSavingBugTicket = false;

  showBugTicketPopup = false;

  hideAddImages = false;

  defaultBugForm = {
    title: '',
    description: '',
  };

  bugForm = {
    title: '',
    description: '',
  };

  shortcutApiUrl = 'https://api.app.shortcut.com/api/v3/search/stories';

  defaultColDef = {
    initialWidth: 350,
    sortable: true,
    filter: true,
    editable: false,
    autoHeight: true,
    cellStyle: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      overflow: 'visible',
      'white-space': 'normal',
    },
    resizable: false,
  };

  rowData: any = [];

  cellClick() {
    // console.log(e);
  }

  supportTicketFiles: any = [];

  updateTicketFiles(files) {
    this.supportTicketFiles = files;
  }

  getRowNodeId: any = null;
  searchLabels: any = ['label:"visible to all"'];

  gridApi: any = null;

  gridColumnApi: any = null;

  dataLoading = false;

  get operatorNameForShortcut() {
    const name = getConfigEnv('OPERATOR_PASCALED').toLowerCase();
    if (name === 'petronascanada') {
      return 'petronas';
    }
    return name;
  }

  getUniqueObjectsByField(objects, field) {
    const uniqueObjectsByField = [];

    objects.forEach((object) => {
      if (
        !uniqueObjectsByField.some(
          (otherObject) => otherObject[field] === object[field]
        )
      ) {
        uniqueObjectsByField.push(object);
      }
    });

    return uniqueObjectsByField;
  }

  beforeMount() {
    this.context = { componentParent: this };
    // @ts-ignore
    window.gridApiVue = this;
  }



  labelID: any = [];

  async created() {
    this.rowData = [];
    this.searchLabels.push(
      `label:"visible to ${this.operatorNameForShortcut}"'`
    );

    this.dataLoading = true;

    const issuesLabels =  await workflowModule.gettLinearIssuesLabels()
   if(issuesLabels && issuesLabels.length){
    this.labelID =  issuesLabels.map(i => i.id)
   }

    await this.getOperatorShortcutStories();
    this.dataLoading = false;

  }

  async getOperatorShortcutStories() {
    const stories =  await workflowModule.gettLinearIssues()
    if (stories && stories.length) {
      const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/;
      const currentDate = new Date();
      const thirtyDaysAgo = new Date(currentDate.getTime() - (30 * 24 * 60 * 60 * 1000));



      this.rowData = stories.filter(s => s.completedAt)
        .map((story) => {
          let userEmail =  '-';
          if(story.description && story.description.length > 3){
            const match = story.description.match(emailPattern);
            userEmail = match ? match[0] : '-';
            userEmail = userEmail !== '-' ? getNameByEmail(userEmail.replace(/-+/g, '')): userEmail;
          }
          return {
            name: story.title,
            submittedDate: story.createdAt && story.createdAt.length > 3
                ? story.createdAt
                : null,
                completedAt:
              story.completedAt && story.completedAt.length > 3
                ? story.completedAt
                : null,
                deadlineTimestamp:
              story.completedAt && story.completedAt.length > 3
                ? new Date(story.completedAt).getTime()
                : 0,
            description:
              story.description && story.description.length > 1
                ? story.description.split('-------')[0]
                : '-',
                userEmail: userEmail,
          };
        }).filter(e => e.deadlineTimestamp  > thirtyDaysAgo.getTime())
        .sort((a, b) =>  (b.deadlineTimestamp) - (a.deadlineTimestamp))

    }else{
      this.rowData = []
    }
  }

  async onGridReady(params) {
    this.gridApi = params.api;
    // this.rowData = [];
    this.columnDefsActiveStories = [];
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();

    this.columnDefsActiveStories = [
      {
        field: 'name',
        cellClass: 'noborder',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },
        sortable: true,
        filter: false,
        width: 390,
        colId: 'name',

        headerName: 'Ticket Description',
      },
      {
        field: 'submittedDate',
        editable: false,
        width: 180,
        colId: 'submittedDate',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: '1px solid rgba(255, 255, 255, 0.1)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },
        valueFormatter: (params) => {
          if (
            params.value === '-' ||
            params.value === null ||
            params.value === undefined
          ) {
            return '-';
          }
          return new Date(params.value).toDateString();
        },
        cellClass: 'noborder',
        headerName: 'Submitted Date',
      },
      {
        field: 'userEmail',
        editable: false,
        width: 180,
        colId: 'userEmail',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: '1px solid rgba(255, 255, 255, 0.1)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },

        cellClass: 'noborder',
        headerName: 'User',
      },
      {
        field: 'completedAt',
        editable: false,
        initialWidth: 350,
        width: 220,
        colId: 'completedAt',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: '1px solid rgba(255, 255, 255, 0.1)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },
        valueFormatter: (params) => {
          if (
            params.value === '-' ||
            params.value === null ||
            params.value === undefined
          ) {
            return '-';
          }
          return new Date(params.value).toDateString();
        },
        cellClass: 'noborder',
        headerName: 'Tasq Completed Date',
      },
    ];

    await sleep(1000);
    this.gridColumnApi.autoSizeColumns();
  }

  onFirstDataRendered(params) {
    this.gridApi.sizeColumnsToFit();
  }

  async submitBugForm() {
    this.isSavingBugTicket = true;
    const bugForm = this.bugForm;
    await sleep(1000);
    bugForm['description'] = bugForm['description'] +`\n\n -------` + accountModule.user.email + `\n\n -------`;
    if (this.supportTicketFiles.length) {
      const filesUrls = this.supportTicketFiles.map((file) => {
        return `![${file.name}](${encodeURI(file.url)})`;
      });
      bugForm['description'] = `${bugForm['description']} \n ${filesUrls.join(
        '\n-----------------------\n'
      )}`;
    }
    await sleep(3000);
    await workflowModule.postLinearBug({...bugForm, labelID: this.labelID });
    await sleep(1000);
    await this.getOperatorShortcutStories();
    this.isSavingBugTicket = false;
    this.bugForm = {
      title: '',
      description: '',
    };
    this.supportTicketFiles = [];
    this.showBugTicketPopup = false;
  }
}
