<template>
    <div>
      <div v-if="expanded" v-html="data"></div>
      <div v-else class="pt-1">{{ truncatedData }}</div>
      <button
        v-if="isExpandable"
        class="text-tasqBase100 py-1 inline-block text-xs"
        @click="toggleExpand"
      >
        {{ expanded ? 'Collapse' : 'See More' }}
      </button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        expanded: false,
        value: '',
      };
    },
    computed: {
      truncatedData() {
        const MAX_CHARS_PER_LINE = 88; // Set the number of characters per line
        const lines = this.splitTextIntoLines(this.value, MAX_CHARS_PER_LINE);
        if (lines.length <= 3) {
          return this.value;
        }
        return lines.slice(0, 3).join('');
      },
      data() {
        return this.value;
      },
      isExpandable() {
        const MAX_CHARS_PER_LINE = 88; // Set the number of characters per line
        const lines = this.splitTextIntoLines(this.value, MAX_CHARS_PER_LINE);
        return lines.length > 3;
      },
    },
    created() {
      this.value = this.params.data['description'];
    },
    methods: {
      toggleExpand() {
        this.expanded = !this.expanded;
      },
      splitTextIntoLines(text, maxCharsPerLine) {
        const lines = [];
        let line = '';
        for (let i = 0; i < text.length; i++) {
          line += text[i];
          if ((i + 1) % maxCharsPerLine === 0 || i === text.length - 1) {
            lines.push(line);
            line = '';
          }
        }
        return lines;
      },
    },
  };
  </script>